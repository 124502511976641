<div class="main-header">
    <div class="logo">
        <img [src]="websiteImg" alt="">
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <!-- <div class="d-flex align-items-center">
    
        <div class="search-bar">
            <input type="text" placeholder="Search" (focus)="searchService.searchOpen = true">
            
        </div>
    </div> -->

    <div style="margin: auto"></div>

    <div class="header-part-right">
        <!-- <i class="i-Magnifi-Glass1 header-icon" role="button" (click)="searchService.searchOpen = true"></i> -->
        <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>
        <!-- <div ngbDropdown [placement]="'bottom-right'" class="d-none d-sm-inline-block">
            <i ngbDropdownToggle class="i-Gear text-muted header-icon" role="button"></i>
            <div ngbDropdownMenu class="menu-icon-grid-dropdown">
                <div class="menu-icon-grid">
                    <a href="#"><i class="i-Shop-4"></i> Home</a>
                    <a href="#"><i class="i-Library"></i> UI Kits</a>
                    <a href="#"><i class="i-Drop"></i> Apps</a>
                    <a href="#"><i class="i-File-Clipboard-File--Text"></i> Forms</a>
                    <a href="#"><i class="i-Checked-User"></i> Sessions</a>
                    <a href="#"><i class="i-Ambulance"></i> Support</a>
                </div>
            </div>
        </div> -->

        <div ngbDropdown [placement]="'bottom-right'">
            <!-- <div class="badge-top-container">
                <span class="badge badge-primary">{{notifications.length}}</span>
                <i ngbDropdownToggle class="i-Bell text-muted header-icon" role="button"></i>
            </div> -->
            <div ngbDropdownMenu class="notification-dropdown rtl-ps-none" perfectScrollbar>
                <div class="dropdown-item d-flex" *ngFor="let item of notifications" [routerLink]="[item.link]">
                    <div class="notification-icon">
                        <i class="{{item.icon}} text-{{item.status}} me-1"></i>
                    </div>
                    <div class="notification-details flex-grow-1">
                        <p class="m-0 d-flex align-items-center">
                            <span>{{item.title}}</span>
                            <span *ngIf="item.badge" class="badge badge-pill badge-{{item.status}} ms-1 me-1">{{item.badge}}</span>
                            <span class="flex-grow-1"></span>
                            <span class="text-small text-muted ms-auto">{{item.time}}</span>
                        </p>
                        <p class="text-small text-muted m-0">{{item.text}}</p>
                    </div>
                </div>
            </div>
        </div>

        <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
            <img [src]="userInfo?.image" id="userDropdown" ngbDropdownToggle alt="">

            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                <div class="dropdown-header">
                    <i class="i-Lock-User me-1"></i> {{userInfo?.name}}
                </div>
                <button class="dropdown-item" routerLink="/pages/profile">الصفحة الشخصية</button>
                <!-- <button class="dropdown-item">Billing history</button> -->
                <button class="dropdown-item" (click)="signout()">تسجيل خروج</button>
            </div>
        </div>

    </div>

</div>