<div class="card">
    <div class="card-header">
      <i class="i-Newspaper"></i> 
     <span class="mx-2">إضافة مؤشر جديد </span>  

     </div>
   
    <div class="card-body">
        <form [formGroup]="pointerForm" (ngSubmit)="onSubmit()">
            <!-- <div class="form-group">
              <label>* العنوان</label>
              <input
                type="text"
                formControlName="title"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f['title'].errors }"
                placeholder=" الاسم"
              />
              <div *ngIf="submitted && f['title'].errors" class="invalid-feedback">
                <div *ngIf="f['title'].errors['required']"> العنوان مطلوب</div>
              </div>
            </div> -->

            <div class="form-group">
              <label>* الوصف</label>
              <textarea class="form-control" formControlName="discription"  rows="3"
              [ngClass]="{ 'is-invalid': submitted && f['discription'].errors }" ></textarea>
              <div *ngIf="submitted && f['discription'].errors" class="invalid-feedback">
                <div *ngIf="f['discription'].errors['required']"> الوصف مطلوب</div>
              </div>
            </div>


            <div class="form-group">
              <label>* الصورة</label>
              <input
              type="file"
              formControlName="photo"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f['photo'].errors }"
              placeholder=" الاسم"
              (change)="onFileChange($event)"  accept="image/*" />
              <div *ngIf="submitted && f['photo'].errors" class="invalid-feedback">
                <div *ngIf="f['photo'].errors['required']"> الصورة مطلوب</div>
              </div>
            </div>

            <div>
              <img [src]="viewImgUrl" id="output" alt="" style="height: 300px; width: 100%; " >
            </div>
      
        
            <div class="form-group">
                <button  type="submit" class="btn btn-primary my-2" (click)="EditPointer()">إضافة</button>
                <button
                  type="button"
                  (click)="onReset()"
                  class="btn btn-danger mx-2"
                >
                  مسح
                </button>
              </div>

          </form>
    </div>
  </div>
